<!--
 * @Author: zll zll1217168026@163.com
 * @Date: 2022-07-28 10:55:53
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-09-07 20:42:17
 * @FilePath: \ding-lims\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`
-->
<template>
  <div class="home">
    <div class="plr16 des">
      <p>欢迎您，{{ user.nickname }}。</p>
    </div>
    <van-grid :gutter="10" :column-num="3">
      <van-grid-item v-for="(item, idx) in menu" :key="idx" icon-prefix="icon-font" :icon="item.menu_icon" :text="item.title" :to="{ path: item.url }" />
    </van-grid>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { Grid, GridItem, Icon } from 'vant'
import store from '@/store/index'

Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Icon)

export default {
  name: 'Home',
  components: {
  },
  data() {
    return { }
  },
  created() {   
    // const user = this.$store.state.router.user
    // console.log(user);
    // if (!this.$common.isEmpty(user)) {
    //   this.getMenu()
    // }
    this.asyncMenuData()
  },
  computed: {
    menu: () => {
      return store.getters['router/menuData']
    },
    user: () => {
      return store.getters['router/user']
    }
  },
  // watch: {
  //   // '$route'(to, from) {
  //   //   console.log(from)
  //   //   if (this.$route.query.back === '1') {
  //   //     this.$router.push(localStorage.getItem('curl'))
  //   //   }
  //   // }
  //   user: function (val) {
  //     console.log(val)
  //     if (!this.$common.isEmpty(val)) {
  //       this.getMenu()
  //     }
  //   },
  // },
  methods: {
    ...mapActions('router', ['asyncMenuData', 'asyncUserLogin']),
    // CheckAuthorization() {
    //   let Authorization = localStorage.getItem('Authorization')
    //   try {
    //     Authorization = JSON.parse(Authorization)
    //   } catch (error) {
    //     // eslint-disable-next-line no-self-assign
    //     Authorization = Authorization
    //   }
    //   if (!Authorization) {
    //     this.Login()
    //   } else {
    //     const user = this.$store.state.router.user
    //     const menu = this.$store.state.router.menuData
    //     if (user) {
    //       this.user = user
    //     } else {
    //       this.asyncUser().then(res => { 
    //         this.user = res
    //       })
    //     }
    //     if (menu.lenth > 0) {
    //       this.menu = menu
    //     } else {
    //       this.asyncMenuData().then(res => { 
    //         this.menu = res
    //       })
    //     }
    //   }
    // },
    getMenu() {
      const menu = this.$store.state.router.menuData
      if (menu.length > 0) {
        this.menu = menu
      } else {
        this.asyncMenuData().then(res => {
          this.menu = res
        })
      }
    },
  }
}
</script>

<style>
.des{
  font-size: 12px;
  line-height: 1.628em;
  padding: 10px 16px;
}
</style>
